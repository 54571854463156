<template>
  <div class="details displayF">
    <div class="left displayF flex-directionC justify-contentB">
      <div class="top">
        <div class="title">联系我们</div>
        <div class="conten">让您满意是我们服务最好的证明！</div>
        <div class="conten">高效/优质/敏捷</div>
        <div class="Timg">
          <img src="../../assets/diannao.png" alt="" class="image" />
        </div>
      </div>
      <div class="bottom">
        <div class="title">现在联系四川思达扬科</div>
        <div class="title">立即定制您的解决方案</div>
        <div class="phone">18113060375</div>
        <div class="emils">销售邮箱：sdyk@sidayk.com</div>
      </div>
    </div>
    <div class="right">
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        class="demo-ruleForm displayF flex-directionC justify-contentC align-itemsC"
      >
        <div class="one displayF justify-contentB">
          <div class="word">
            <div class="title"><span style="color: red">*</span>您的姓名</div>
            <el-form-item prop="name">
              <el-input
                v-model="ruleForm.name"
                placeholder="请输入您的姓名"
              ></el-input>
            </el-form-item>
          </div>
          <div class="word">
            <div class="title"><span style="color: red">*</span>联系方式</div>
            <el-form-item prop="phone">
              <el-input
                v-model="ruleForm.phone"
                placeholder="请输入您的联系方式"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="two displayF justify-contentB">
          <div class="word">
            <div class="title"><span style="color: red">*</span>联系地址</div>
            <el-form-item prop="address">
              <el-input
                v-model="ruleForm.address"
                placeholder="请输入您的联系地址"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="three displayF justify-contentB">
          <div class="word">
            <div class="title"><span style="color: red">*</span>合作需求</div>
            <el-form-item prop="text">
              <el-input
                type="textarea"
                v-model="ruleForm.text"
                placeholder="请输入您的合作需求"
                :rows="4"
              ></el-input>
            </el-form-item>
          </div>
        </div>
        <div class="four">
          <el-form-item>
            <el-button
              type="primary"
              @click="submitForm('ruleForm')"
              class="bot"
              >提交</el-button
            >
          </el-form-item>
        </div>
      </el-form>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  props: {},
  data() {
    // 判定账户是电话号码
    let checkusername = (rule, value, callback) => {
      let regex = /^(13[0-9]|14[01456879]|15[0-35-9]|16[2567]|17[0-8]|18[0-9]|19[0-35-9])\d{8}$/;
      if (!regex.test(value)) {
        return callback(new Error("请输入正确的手机号"));
      } else {
        return callback();
      }
    };
    return {
      ruleForm: {
        name: "",
        phone: "",
        address: "",
        text: "",
      },
      rules: {
        name: [{ required: true, message: "姓名不能为空", trigger: "blur" }],
        phone: [
          { required: true, message: "联系方式不能为空", trigger: "blur" },
          {
            validator: checkusername,
            trigger: "blur",
          },
        ],
        address: [
          { required: true, message: "联系地址不能为空", trigger: "blur" },
        ],
        text: [
          { required: true, message: "合作需求不能为空", trigger: "blur" },
        ],
      },
    };
  },
  components: {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$api
            .getIndex({
              address: this.ruleForm.address,
              content: this.ruleForm.text,
              name: this.ruleForm.name,
              phone: this.ruleForm.phone,
            })
            .then((res) => {
              if (res.code === 0) {
                this.$message({
                  message: "提交成功",
                  type: "success",
                });
                this.$refs[formName].resetFields();
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else {
          return false;
        }
      });
    },
  },
  mounted() {},
  computed: {},
  watch: {},
};
</script>

<style lang='scss' scoped>
.details {
  width: 100%;
  height: 100%;
  background: url("../../assets/shoujixinxi.png");
  background-size: 100% 100%;
  .left {
    width: 42%;
    height: 100%;
    padding: 2.25rem 3.125rem 6.25rem 3.125rem;
    .top {
      .title {
        width: 100%;
        text-align: center;
        font-size: 2.5rem;
        color: #ffffff;
      }
      .conten {
        margin-top: 1.25rem;
        font-size: 1.875rem;
        color: #ffffff;
        text-align: center;
      }
      .Timg {
        text-align: end;
        .image {
          width: 25rem;
          height: 25rem;
        }
      }
    }
    .bottom {
      .title {
        font-size: 1.25rem;
        color: #ffffff;
      }
      .phone {
        font-size: 1.875rem;
        color: #faff0d;
        margin: 1rem 0;
      }
      .emils {
        font-size: 1.25rem;
        color: #ffffff;
      }
    }
  }
  .right {
    width: 58%;
    height: 100%;
    .demo-ruleForm {
      width: 100%;
      height: 100%;
      .one {
        width: 80%;
        .word {
          width: 45%;
          .title {
            font-size: 1rem;
            color: #2e73de;
            margin-bottom: 0.625rem;
          }
        }
      }
      .two {
        width: 80%;
        margin: 2.625rem 0;
        .word {
          width: 45%;
          .title {
            font-size: 1rem;
            color: #2e73de;
            margin-bottom: 0.625rem;
          }
        }
      }
      .three {
        width: 80%;
        margin-bottom: 2.625rem;
        .word {
          width: 100%;
          .title {
            font-size: 1rem;
            color: #2e73de;
            margin-bottom: 0.625rem;
          }
        }
      }
      .four {
        width: 80%;
        .bot {
          width: 21.875rem;
        }
      }
    }
  }
}
</style>